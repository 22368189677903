const initialState = [];

const createEdge = (state= initialState, action) =>{
    switch (action.type) {
        case "CREATE_EDGE":
            return state = action.payload
        default:
            return state;
    }
}

export default createEdge;
