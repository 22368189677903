import React, { useEffect, useState } from "react";
import Heading from "../../components/SectionHeading/Heading";
import WarningIcons from "../../components/WarningIcons/WarningIcons";
import axios from "axios";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import { useDispatch, useSelector } from "react-redux";
import { setEdgeStatus } from "../../reduxStore/actions";
import "../../components/Podstatus/Widget.css";
import { IoIosAdd, IoIosRemove } from "react-icons/io";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { Table } from "antd";
import SpinnerLoader from "../../components/SpinnerLoader/SpinnerLoader";
import { setResourceMonitorGraph } from "../../reduxStore/actions";

function ResourceMonitorPodList() {
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");
  const apiReloader = useSelector((state) => state.setApiReloader);
  const dispatch = useDispatch();
  const [serviceData, setServiceData] = useState([]);
  const [refactoredPods, setRefactoredPods] = useState([]);
  const isSummaryVisible = useSelector((state) => state.setSummaryVisible);
  const [customHeight, setCustomHeight] = useState(0);
  const [isLoading, setisLoading] = useState(true);
  const [sudoState, setSudoState] = useState(0);

  const adjustHeight = () => {
    let getMainContainer = document.getElementsByClassName("gridContainer");

    // console.log(getMainContainer)

    let finalHeight = getMainContainer[0].clientHeight - 120;

    setCustomHeight(finalHeight);
  };

  let rtime;
  let timeout = false;
  let delta = 100;

  window.addEventListener("resize", (event) => {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;
      adjustHeight();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      resizeend();
    }, 200);
  }, [isSummaryVisible]);

  useEffect(() => {
    //Fetch Edge Details Status
    let statusEdgeDetailsURL = apiEndPoints.FETCH_STATUS_EDGE_DETAILS.replace(
      "*edgeId*",
      edgeId
    );
    let Fetch_Status = {
      method: "get",
      maxBodyLength: Infinity,
      url: statusEdgeDetailsURL,
      headers: {
        // "Content-Type": "application/json",
      },
    };

    axios
      .request(Fetch_Status)
      .then((response) => {
        dispatch(
          setEdgeStatus({ payload: { data: response.data, isLoading: false } })
        );

        const PodData =
          response.data.edgeServiceMetricsSummary.edgeApplicationPodMetrics;

        // console.log(
        //   "This is a pod data ",
        //   PodData.filter((app) => app.isPlatform == true)
        // );

        const filteredPods = PodData?.filter((app) => app.isPlatform == true);
        setServiceData(filteredPods);
        setisLoading(false);
        setSudoState((prev) => prev + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [apiReloader]);

  useEffect(() => {
    if (serviceData?.length == 0) {
      return;
    }
    let correctedPods = [];
    serviceData?.map((pod, key) => {
      // console.log(pod);
      if (pod?.edgePodStatisticsList) {
        pod.edgePodStatisticsList.map((service, key) => {
          correctedPods.push({
            key: `service_${service.serviceNamePod}`,
            name: service.serviceNamePod,
            status:
              service.statusPod == "Running" ? (
                <WarningIcons type="good" />
              ) : (
                <WarningIcons type="medium" />
              ),
            cpu: service.cpuPod,
            memory: service.ramPod,
          });
        });
      }
    });
    setRefactoredPods(correctedPods);
  }, [serviceData]);

  const columns = [
    {
      title: "Pods",
      dataIndex: "name",
      // key: 'name',
      width: "40%",
      ellipsis: true,
      render: (name) => <a>{name}</a>,
    },
    {
      title: "Status",
      dataIndex: "status",
      // key: 'age',
      width: "15%",
      ellipsis: true,
    },
    {
      title: "CPU %",
      dataIndex: "cpu",
      width: "20%",
      ellipsis: true,
      render: (cpu) => <span>{parseFloat(cpu).toFixed(2)}</span>,
    },
    {
      title: "Memory GB",
      dataIndex: "memory",
      width: "20%",
      ellipsis: true,
      render: (memory) => <span>{parseFloat(memory).toFixed(2)}</span>,
    },
  ];

  const generateTable = () => {
    return (
      <Table
        columns={columns}
        // rowSelection={{
        //   ...rowSelection,
        //   checkStrictly,
        // }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              console.log({ graphType: "pod", data: record });
              dispatch(
                setResourceMonitorGraph({
                  payload: { graphType: "pod", data: record, isLoading: false },
                })
              );
            }, // click row
          };
        }}
        pagination={false}
        dataSource={refactoredPods}
        size="small"
        scroll={{
          y: customHeight - 45,
        }}
        // expandable={{
        //   // expandedRowRender: record => (<p>{record.children[0].name}</p>),
        //   expandIcon: ({ expanded, onExpand, record }) => {
        //     if (record?.children?.length > 0) {
        //       // console.log("Recordddd: ", record);
        //       return expanded ? (
        //         <BiChevronDown
        //           style={{
        //             fontSize: "20px",
        //             marginBottom: "-5px",
        //             width: "30px",
        //             marginLeft: "-10px",
        //           }}
        //           onClick={(e) => onExpand(record, e)}
        //         />
        //       ) : (
        //         <BiChevronRight
        //           style={{
        //             fontSize: "20px",
        //             marginBottom: "-5px",
        //             width: "30px",
        //             marginLeft: "-10px",
        //           }}
        //           onClick={(e) => onExpand(record, e)}
        //         />
        //       );
        //     }
        //   },
        // }}
      />
    );
  };
  //  podsModulesData : {data:[{},{}], selection:'pod/module'}
  return (
    <div className="App podStatusApp">
      <div
        className="Heading_container"
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: "10px",
          // width: "44.5%",
          // borderBottom: "1px solid #e2e2e2",
          marginTop: "10px",
        }}
      >
        <Heading text="Edge Core Runtime" />
        {isLoading && <SpinnerLoader />}
      </div>

      <div
        style={{
          // height: `${
          //   isSummaryVisible ? "calc(52vh - 40vh)" : "calc(80vh - 42vh)"
          // }`,
          padding: "10px",
          height: `${customHeight}px`,
          // overflow: "auto",
          transition: "0.3s",
          // background:'pink'
        }}
      >
        <div className="podsTable">{!isLoading && generateTable()}</div>
      </div>
    </div>
  );
}

export default ResourceMonitorPodList;
