import "./Widget.css";
import Heading from "../SectionHeading/Heading";

import { AreaChart, Area, XAxis, YAxis } from "recharts";
import { useSelector } from "react-redux";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import { useState, useEffect } from "react";
import NoDataComponent from "../NoDataComponent/NoDataComponent";
import { General_color } from "../../constants/GeneralConstants";
import { Tooltip } from "antd";

function Widget() {
  const matricsData = useSelector((state) => state.setData.data);
  const matricsData_isLoading = useSelector((state) => state.setData.isLoading);
  const graphData = useSelector((state) => state.setGraph.data);
  const graphData_isLoading = useSelector((state) => state.setGraph.isLoading);
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");

  const [chartWidth, setChartWidth] = useState(300);

  let rtime;
  let timeout = false;
  let delta = 200;

  window.addEventListener("resize", (event) => {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;

      let ele = document.getElementById("contentContainer");

      let num = ele.offsetWidth;
      // console.log(num)
      num = num;

      setChartWidth(num - 10);
    }
  }

  useEffect(() => {
    resizeend();
  }, []);

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const timestamp = 1701410944;
  const date = new Date(timestamp * 1000);
  const datevalues = [
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ];

  const getTheTime = (timeStamp) => {
    const date = new Date(timestamp * 1000);
    // const datevalues = [
    //    date.getFullYear(),
    //    date.getMonth()+1,
    //    date.getDate(),
    //    date.getHours(),
    //    date.getMinutes(),
    //    date.getSeconds(),
    // ];
    // console.log(datevalues)

    return date.getMinutes();
  };

  const generateMetrixRow = (
    title,
    description,
    usage,
    total,
    chartData,
    graphLimit
  ) => {
    return (
      <tr>
        <td
          style={{
            borderBottom: "1px solid #f0f0f0",
            width: "50%",
            padding: "0px 10px",
            height: "100px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
              // justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                fontSize: "11px",
                gap: "5px",
                paddingRight: "5px",
                // width: "100px",
                marginTop: "7px",
              }}
            >
              {/* <span>{graphLimit.upper}</span>
              <span>{graphLimit.middle}</span>
              <span>{graphLimit.lower}</span> */}
              <b style={{ fontSize: "18px" }}>{title}</b>
              <span style={{ fontSize: "15px" }}>{usage}</span>
            </div>
            <div style={{ cursor: "pointer" }}>
              <a
                target="_blank"
                href="https://observability.cod.abb.com/d/vao3GlrVk/overview?orgId=1"
                style={{
                  marginLeft: "auto",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                <AreaChart
                  width={chartWidth}
                  height={70}
                  data={chartData}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                  
                >
                  {/* <XAxis dataKey={getTheTime("t")} /> */}
                  {/* <YAxis     dataKey="v" /> */}
                  <Area
                    type="monotone"
                    dataKey="v"
                    stroke="#00308F"
                    fill="#D1EBFF"
                  />
                </AreaChart>
              </a>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "90%",
                }}
              >
                <span style={{ fontSize: "15px" }}>{description}</span>
                <span style={{ fontSize: "15px" }}>{total}</span>
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  function bytesToSize(bytes, seperator = "") {
    // const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "n/a";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(11)), 10);
    if (i === 0) return `${bytes}${seperator}`;
    return `${(bytes / 10 ** i).toFixed(2)}${seperator}`;
  }

  return (
    <div className="App contentApp" id="contentContainer">
      {/* <span
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: "10px",
        }}
      > */}
      <div
        className="Heading_container"
        style={{
          // display: "flex",
          // alignItems: "center",
          // columnGap: "10px",
          // width: "100%",
          // // marginTop: "21px",
          // borderBottom: "1px solid #e2e2e2",
          display: "flex",
          alignItems: "center",
          columnGap: "10px",
          width: "98%",
          borderBottom: "1px solid #f0f0f0",
        }}
      >
        <Heading text="Content" />
        {matricsData_isLoading && graphData_isLoading && <SpinnerLoader />}
      </div>
      {/* </span> */}
      {/* <hr style={{marginTop: '30px' }}/> */}
      <div style={{ height: "37vh", overflowY: "auto", overflowX: "hidden" }}>
        <table style={{ width: "100%" }}>
          {/* {content.map((c, key) => ( */}

          {!matricsData_isLoading &&
            matricsData.length > 0 &&
            !graphData_isLoading &&
            graphData.length > 0 && (
              <>
                {generateMetrixRow(
                  "CPU",

                  `${
                    matricsData[0].data[0].v * 1000 -
                    (Math.round(matricsData[1].data[0].v) / 100) *
                      (matricsData[0].data[0].v * 1000)
                  } milli core available`,

                  `${Math.round(matricsData[1].data[0].v)}%`,

                  `of ${matricsData[0].data[0].v} core limit`,

                  graphData[0].data,

                  { upper: "1000 m", middle: "500 m", lower: "0 m" }
                )}
                {generateMetrixRow(
                  "Memory",

                  `${(
                    bytesToSize(matricsData[2].data[0].v) -
                    (matricsData[3].data[0].v / 100) *
                      // (Math.round(matricsData[3].data[0].v) / 100) *
                      bytesToSize(matricsData[2].data[0].v)
                  ).toFixed(2)} GB available`,

                  `${Math.round(matricsData[3].data[0].v)}%`,

                  `of ${bytesToSize(matricsData[2].data[0].v)} GB limit`,

                  graphData[1].data,

                  { upper: "12.0 GB", middle: "6.0 GB", lower: "0 MB" }
                )}
                {generateMetrixRow(
                  "Storage",
                  `${(
                    bytesToSize(matricsData[4].data[0].v) -
                    (Math.round(matricsData[5].data[0].v) / 100) *
                      bytesToSize(matricsData[4].data[0].v)
                  ).toFixed(2)} GB available`,
                  `${Math.round(matricsData[5].data[0].v)}%`,
                  `of ${bytesToSize(matricsData[4].data[0].v)} GB limit`,
                  graphData[2].data,
                  { upper: "25.0 GB", middle: "12.5 GB", lower: "0 GB" }
                )}
              </>
            )}

          {!matricsData_isLoading &&
            matricsData.length == 0 &&
            !graphData_isLoading &&
            graphData.length == 0 && <NoDataComponent />}
        </table>
        {/* <div
          style={{
            padding: "3px 10px",
            position: "absolute",
            bottom: "0",
            background: "white",
            width: "100%",
          }}
        >
          <a
            href={`/edgeResources?edgeId=${edgeId}`}
            style={{
              textDecoration: "none",
              color: General_color.info,
            }}
            className="Hyper_link"
          >
            {" "}
            <Tooltip title="Coming soon">
              <b style={{ fontSize: "18px" }}>Edge Resource Monitor</b>
            </Tooltip>
          </a>
        </div> */}
      </div>
    </div>
  );
}

export default Widget;
