import React, { useEffect, useState } from "react";
import Sidebar from "../components/SideBar/Sidebar";
import EdgeDetailsComponents from "./pageComponents/EdgeDetailsComponents";
import axios from "axios";
import { apiEndPoints } from "../constants/ApiEndPoints";
import { useDispatch, useSelector } from "react-redux";
import { setMetrics,setGraph,setEventLog, setApiReloader } from "../reduxStore/actions";
import { useParams } from "react-router-dom";
import { GeneralConstants } from "../constants/GeneralConstants";


function Edgedetails() {
  // const {edgeId, platformType} = useParams();
  const dispatch = useDispatch();
 const queryParam = new URLSearchParams(window.location.search)
  const edgeId = queryParam.get("edgeId")
  let platformType = useSelector(state=>state.setEdgeDetails?.data?.[4]?.detail)
  let edgeName = queryParam.get("edgeName")
  const location = queryParam.get("location")
  let isEdgeDeploying = useSelector((state) => state.setIsEdgeDeploying);
// console.log(edgeId, platformType)
const apiReloadTime = GeneralConstants.apiReloadTime;
const [reloadAPI, setReloadAPI] = useState(0)

const apiReloader = useSelector(state=>state.setApiReloader)

useEffect(()=>{

  dispatch(setApiReloader({payload:0}))
},[])

useEffect(()=>{
   if(isEdgeDeploying){
    return
   }
    setTimeout(()=>{
      dispatch(setApiReloader({payload:apiReloader+1}))
     
    },apiReloadTime)
 

},[apiReloader, isEdgeDeploying])


  useEffect(() => {
    if(isEdgeDeploying){
      return
     }
     
    // Fetch content Metrics

    let Fetch_Metrics = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiEndPoints.FETCH_METRICS,
      headers: {
        "Content-Type": "application/json",
       
      },
      data: JSON.stringify({
        metrics: [
          "CPU_COUNT",
          "CPU_USAGE",
          "RAM",
          "RAM_USED",
          "DISK_SIZE",
          "DISK_USED",
        ],
        filters: [
          {
            name: "edge_uid",
            value:edgeId,
          },
        ],
      }),
    };

    axios
      .request(Fetch_Metrics)
      .then((response) => {
        dispatch(setMetrics({ payload:{"data":response.data,"isLoading":false} }));
      })
      .catch((error) => {
        console.log(error);
      });

    // Fetch Graph

    let Fetch_Graph = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiEndPoints.FETCH_GRAPH,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        metrics: ["CPU_USAGE", "RAM_USED", "DISK_USED"],
        filters: [
          {
            name: "edge_uid",
            value:edgeId,
          },
        ],
      }),
    };

    axios
      .request(Fetch_Graph)
      .then((response) => {
        dispatch(setGraph({ payload: {"data":response.data,"isLoading":false} }));
      })
      .catch((error) => {
        console.log(error);
      });
    // Fetch Events
    let Fetch_Events = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiEndPoints.FETCH_EVENTS,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        filters: [
          {
            name: "edge_uid",
            value: edgeId,
          },
        ],
        
        start: "",
        end: "",
        limit: 0,
      }),
    };

    axios
      .request(Fetch_Events)
      .then((response) => {
        dispatch(setEventLog({ payload: {"data":response.data, "isLoading":false} }));
        
      })
      .catch((error) => {
        console.log(error);
      });
  }, [apiReloader]);

  return (
    <section className="row-flex">
      <div className="sidebarContainer">
        <Sidebar />
      </div>
      <div className="MainContainer">
        <EdgeDetailsComponents edgeName={edgeName} edgeId={edgeId} platformType={platformType} location={location}/>
      </div>
    </section>
  );
}

export default Edgedetails;
