import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

function DummyAreaChart() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: "100px",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <span>CPU</span>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            {/* <XAxis dataKey="name" />
            <YAxis /> */}
            {/* <Tooltip /> */}
            <Area
              type="monotone"
              dataKey="uv"
              stroke="#00308F"
              fill="#D1EBFF"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>

      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <span>RAM</span>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            {/* <XAxis dataKey="name" />
            <YAxis /> */}
            {/* <Tooltip /> */}
            <Area
              type="monotone"
              dataKey="pv"
              stroke="#00308F"
              fill="#D1EBFF"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default DummyAreaChart;
