import React from "react";
import DummyAreaChart from "../../components/DummyAreaChart";
import { useDispatch, useSelector } from "react-redux";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import { AreaChart, Area, XAxis, YAxis } from "recharts";
import { useState, useEffect } from "react";
import { Switch, Input, Button, Form } from "antd";
import FormItem from "antd/es/form/FormItem";

function ResourceMonitorGraphs() {
  const pod_graphData = useSelector(
    (state) => state.setResourceMonitorGraph.data
  );

  const graphType = useSelector(
    (state) => state.setResourceMonitorGraph.graphType
  );

  console.log("The graph data", pod_graphData);

  const podgraphData_isLoading = useSelector(
    (state) => state.setResourceMonitorGraph.isLoading
  );
  const matricsData = useSelector((state) => state.setData.data);
  const matricsData_isLoading = useSelector((state) => state.setData.isLoading);
  const dispatch = useDispatch();
  const [showLimits, setShowLimits] = useState(false);

  const [cpuValues, setCpuValues] = useState([]);
  const [ramValues, setRamValues] = useState([]);

  const handleToggle = () => {
    setShowLimits(!showLimits);
  };

  const handleReset = () => {
    setCpuValues([]);
    setRamValues([]);
  };

  const handleConfirm = () => {};

  // const generateLimitFields = (index) => {
  //   return (
  //     <div key={index}>
  //       <Input type="text" placeholder={`CPU ${index + 1}`} />
  //       <Input type="text" placeholder={`RAM ${index + 1}`} />
  //     </div>
  //   );
  // };

  const [chartWidth, setChartWidth] = useState(300);
  let rtime;
  let timeout = false;
  let delta = 200;

  window.addEventListener("resize", (event) => {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });
  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;

      let ele = document.getElementById("contentContainer");

      let num = ele?.offsetWidth;
      num = num;

      setChartWidth(num - 10);
    }
  }

  useEffect(() => {
    resizeend();
  }, []);

  const renderGraph_div = (Metric_data) => {
    return (
      <div className="graph-container" style={{ display: "flex", gap: "20px" }}>
        <div
          className="left-panel"
          style={{ width: "50%", marginBottom: "25px" }}
        >
          {graphType !== "pod" && Metric_data.name}
          <DummyAreaChart />
        </div>
        <div className="right-panel">
          <Form
            style={{ display: "flex", gap: "20px", marginTop: "25px" }}
            disabled
          >
            <FormItem>
              <span
                style={{
                  fontFamily: "ABBVoice",
                  fontSize: "13px",
                }}
              >
                {" "}
                CPU{" "}
              </span>
              <Input value={Metric_data?.cpu} />
            </FormItem>
            <FormItem>
              <span
                style={{
                  fontFamily: "ABBVoice",
                  fontSize: "13px",
                }}
              >
                RAM{" "}
              </span>
              <Input value={Metric_data?.memory} />
            </FormItem>
          </Form>
          {showLimits && (
            <div className="limit-fields">
              {/* {pod_grapgData.map((_, index) => generateLimitFields(index))} */}
              <Button onClick={handleReset}>Reset</Button>
              <Button onClick={handleConfirm}>Confirm</Button>
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <div className="App">
      <div className="resource-monitor">
        <div style={{ width: "65%" }}>
          <b style={{ fontFamily: "ABBVoice", fontSize: "18px" }}>Resources</b>
          <Switch
            style={{ float: "right" }}
            defaultChecked={false}
            onClick={handleToggle}
          />
          <b
            style={{
              marginRight: "10px",
              float: "right",
              fontFamily: "ABBVoice",
              fontSize: "15px",
            }}
          >
            Set Limits
          </b>
        </div>

        <div
          style={{
            // height: "37vh",
            // overflowY: "auto",
            // overflowX: "hidden",
            marginTop: "30px",
            marginBottom: "20px",
          }}
        >
          <span
            style={{
              fontFamily: "ABBVoice",
              fontSize: "18px",
            }}
          >
            {pod_graphData?.name}
          </span>
        </div>
        {/* {renderGraph_div(pod_graphData)} */}
        {graphType === "pod"
          ? renderGraph_div(pod_graphData)
          : pod_graphData?.children?.map((item) => renderGraph_div(item))}
      </div>
    </div>
  );
}

export default ResourceMonitorGraphs;
