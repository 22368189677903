import * as React from "react";
import * as PageRoutes from "../../../constants/PageRoutes";

let logOutTimeOut: any;
interface timeoutHandlerProps {
  onLogout: () => void;
}
const TimeOutHandler = (props: timeoutHandlerProps) => {
  const signOutTime = 1000 * 30 * 60;
  // const signOutTime = 20000;
  React.useEffect(() => {
    const eventsList = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
      "keydown",
    ];
    eventsList.map((event) => {
      document.addEventListener(event, resetTimeout);
    });
    startTimeOut();
    return () => {
      eventsList.map((event) => {
        document.removeEventListener(event, resetTimeout);
        clearTimeout(logOutTimeOut);
      });
    };
  }, []);
  const startTimeOut = () => {
    logOutTimeOut = setTimeout(logOut, signOutTime);
  };
  const resetTimeout = () => {
    clearTimeout(logOutTimeOut);
    startTimeOut();
  };
  let keys = Object.keys(PageRoutes.breadcrumbNameMap);
  let saveTheRoutes: { route: string; value: string | null }[] = [];
  let prev_edgeName: any = "";
  const logOut = () => {
    keys.map((route) => {
      saveTheRoutes.push({
        route,
        value: localStorage.getItem(route),
      });
    });
    prev_edgeName = localStorage.getItem("edgeName");
    props.onLogout();
    saveTheRoutes.map((route) => {
      if (route.value == null) {
        return;
      }
      localStorage.setItem(route.route, route.value);
    });
    localStorage.setItem("edgeName", prev_edgeName);
  };
  return <div />;
};

export default TimeOutHandler;
