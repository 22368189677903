import React from "react";
import DashboardDetails from "../../components/DashboardDetails/Dashboarddetails";
import Inventory from "../../components/Inventory/Inventory";
import Status from "../../components/Status/Status";
import Eventlog from "../../components/EventLog/Eventlog";
import QuickLinks from "../../components/QuickLinks/quicklinks";
import { Grid, Box } from "grommet/components";
import ApplicationSummary from "../../components/ApplicationSummary/ApplicationSummary";
import AlledgesComponents from "./AlledgesComponents";
import { Col, Divider, Row } from "antd";

function DashboardComponents({ inventoryData, inventoryLoading }) {
  return (
    <>
      <Row style={{ gridGap: "10px", paddingRight: "20px" }}>
        <Col
          flex="1 1 525px"
          style={{
            columnGap: "10px",
            display: "grid",
            rowGap: "10px",
          }}
        >
          <Row style={{ gridGap: "10px" }}>
            <Col
              className="gridContainer"
              flex="1"
              style={{
                borderRadius: "10px",
                height: "11vh",
              }}
            >
              <DashboardDetails inventoryData={inventoryData} />
            </Col>
          </Row>
          <Row style={{ gridGap: "10px" }}>
            <Col
              className="gridContainer"
              flex="1"
              style={{
                borderRadius: "10px",
                height: "80vh",
              }}
            >
              <ApplicationSummary />
              <AlledgesComponents />
            </Col>
          </Row>
        </Col>
        <Col flex="0 1 100%" sm={24} md={24} lg={24} xl={6}>
          <Row>
            <Col
              className="gridContainer"
              style={{ borderRadius: "10px", height: "92vh", width: "92vw" }}
            >
              <Eventlog />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default DashboardComponents;
