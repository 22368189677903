const initialState = {text:'', icon:''};

const setNewNotification = (state = initialState, action) =>{
    switch (action.type) {
        case "SET_NEW_NOTIFICATION":
            return state = action.payload
        default:
            return state;
    }
}

export default setNewNotification;