import React from "react";
import UnAuthorizedView from "../Common/Components/UnAuthorizedView/UnAuthorizedView";
import { PrivateRoute } from "./PrivateRoutes";
import { useSelector } from "react-redux";
import { IState } from "../Reducer";
import { Switch, Route, withRouter } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import AddToken from "../pages/AddToken";
import Alledges from "../pages/Alledges";
import Allapplications from "../pages/Allapplications";
import Edgedetails from "../pages/Edgedetails";
import Settings from "../pages/AppDetailPage";
import AppDetailPage from "../pages/AppDetailPage";
import AllTenantapplications from "../pages/Alltenantapplications";
import EdgeResourceMonitor from "../pages/EdgeResourceMonitor";
import SoftwareVersions from "../pages/SoftwareVersions";

const AppRoutes = () => {
  const commonState = useSelector((state: IState) => state.commonState);
  return (
    <Switch>
      {/* <PrivateRoute
        exact
        path="/"
        component={!commonState.showUnAuthorized ? Ymls : UnAuthorizedView}
      /> */}
      <Route
        exact
        path="/"
        component={!commonState.showUnAuthorized ? Dashboard : UnAuthorizedView}
      />
      <Route
        exact
        path="/addtoken"
        component={!commonState.showUnAuthorized ? AddToken : UnAuthorizedView}
      />
      {/* <Route
        exact
        path="alledges"
        component={!commonState.showUnAuthorized ? Alledges : UnAuthorizedView}
      /> */}
      <Route
        exact
        path="/edgedetails"
        component={
          !commonState.showUnAuthorized ? Edgedetails : UnAuthorizedView
        }
      />
      <Route
        exact
        path="/allapplications"
        component={
          !commonState.showUnAuthorized ? Allapplications : UnAuthorizedView
        }
      />
      <Route
        path="/appDetails"
        component={
          !commonState.showUnAuthorized ? AppDetailPage : UnAuthorizedView
        }
      />
      <Route
        path="/alltenantapps"
        component={
          !commonState.showUnAuthorized
            ? AllTenantapplications
            : UnAuthorizedView
        }
      />
      <Route
        path="/edgeResources"
        component={
          !commonState.showUnAuthorized ? EdgeResourceMonitor : UnAuthorizedView
        }
      />
      <Route
        path="/softwareversions"
        component={
          !commonState.showUnAuthorized ? SoftwareVersions : UnAuthorizedView
        }
      />
    </Switch>
  );
};
export default withRouter(AppRoutes);
