const initialState = true;

const setSummaryVisible = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SUMMARY_VISIBLE":
      return (state = action.payload);
    default:
      return state;
  }
};

export default setSummaryVisible;
