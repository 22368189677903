import "./Widget.css";
import Heading from "../SectionHeading/Heading";
import ProgressBar from "@ramonak/react-progress-bar";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BiChevronRight } from "react-icons/bi";
import WarningIcons from "../WarningIcons/WarningIcons";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import NoDataComponent from "../NoDataComponent/NoDataComponent";

function Widget() {
  const [processData, setProcessData] = useState({
    title: "Rebuilding cluster data",
    value: 10,
    t: "Started 2 mins ago",
  });

  const [customHeight, setCustomHeight] = useState(0);

  const events = useSelector((state) => state.setEventLog.data);
  const isLoading = useSelector((state) => state.setEventLog.isLoading);

  const adjustHeight = () => {
    let isDashboard = window.location.pathname.includes("/edgedetails");
    let getMainContainer;
    let finalHeight;

    if (isDashboard == false) {
      getMainContainer = document.getElementsByClassName("gridContainer");
      finalHeight = getMainContainer[2].clientHeight - 45;
    } else {
      getMainContainer = document.getElementsByClassName("gridContainer");
     finalHeight = getMainContainer[6].clientHeight - 45;
    }

    // console.log("value of inner height",finalHeight)
    setCustomHeight(finalHeight);
  };

  let rtime;
  let timeout = false;
  let delta = 100;

  window.addEventListener("resize", (event) => {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;
      adjustHeight();
    }
  }

  useEffect(() => {
    resizeend();
  }, []);

  function convertToUserLocalTime(timestampString) {
    const timestamp = parseFloat(timestampString);
    if (isNaN(timestamp)) {
      return "Invalid timestamp";
    }
    const date = new Date(timestamp / 1000000);
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const options = {
      timeZone: userTimeZone,
      hour12: false,
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return date.toLocaleString("en-US", options);
  }

  const toggleShowHide = (id) => {
    document.getElementById(`overFlow_${id}`).classList.toggle("overFlowText");
    document.getElementById(`button_${id}`).classList.toggle("rotate");
  };

  return (
    <div className="App EventApp">
      <span
        className="Heading_container"
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: "10px",
          width: "98%",
          borderBottom: "1px solid #f0f0f0",
          // borderTopRightRadius: "5px",
        }}
      >
        <Heading text="Event Log" />
        {isLoading && <SpinnerLoader />}
      </span>
      <div style={{ height: `${customHeight}px`, overflow: "auto" }}>
        {/* <span>Ongoing</span>
        <hr />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ fontSize: "11px" }}>{processData.t}</span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",
              marginBottom: "5px",
              fontWeight: "600",
            }}
          >
            <span>{processData.title}</span>
            <span>{`${processData.value} %`}</span>
          </div>
          <ProgressBar
            completed={processData.value}
            bgColor="#00308F"
            baseBgColor="#E3F3FF"
            animateOnRender={true}
            height={"10px"}
            borderRadius={"3px"}
            isLabelVisible={false}
          />
        </div> */}
        {/*         
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "5px",
          }}
        >

{events.length > 0 &&
          <span style={{ color: "#807d7d", fontWeight: "800" }}>
            Recent Events
          </span>}
          <span style={{ color: "blue" }}>Pause</span>
        </div> */}
        {events.length > 0 &&
          !isLoading &&
          events.map((e, key) => (
            <div
              style={{
                borderTop: "1px solid #f0f0f0",
                padding: "5px",
                display: "flex",
                flexDirection: "row",
                columnGap: "10px",
                alignItems: "center",
                // justifyContent:'center'
                // width: '100%',
                // background: 'pink',
                overflow: 'hidden',
              }}
             
              key={key}
            >
              <span style={{ fontSize: "15px", minWidth: "50px" }}>
                {convertToUserLocalTime(e.t)}
              </span>
              <span>
                <WarningIcons type={e.metadata.level} style={{fontSize:'18px'}}/>
              </span>
              <span
                className="overFlowText"
                id={`overFlow_${key}`}
                style={{ fontSize: "15px", marginRight: "auto" }}
              >
                {e.v}
              </span>
              <span style={{ marginLeft: "auto" }}>
                {" "}
                <BiChevronRight
                  fontSize={20}
                  style={{ cursor: "pointer" }}
                  className=""
                  id={`button_${key}`}
                  onClick={() => toggleShowHide(key)}
                />{" "}
              </span>
            </div>
          ))}

        {events.length == 0 && !isLoading && <NoDataComponent />}
      </div>
    </div>
  );
}

export default Widget;
