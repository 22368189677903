import React, { useEffect, useState } from "react";
import Sidebar from "../components/SideBar/Sidebar";
import ResourceMonitorGraphs from "./pageComponents/ResourcesMonitorGraphs";
import { Grid, Box } from "grommet/components";

import axios from "axios";
import { apiEndPoints } from "../constants/ApiEndPoints";
import { useDispatch, useSelector } from "react-redux";
import {
  setAvailableApps,
  setInstalledApps,
  setInventory,
} from "../reduxStore/actions";
import { useParams } from "react-router-dom";
import { Col, Row, Select } from "antd";
import Podstatus from "../components/Podstatus/Podstatus";
import ResourceMonitorApplications from "./pageComponents/ResourceMonitorApplications";
import PodsSummary from "../components/PodsSummary/PodsSummary";
import ResourceMonitorPodList from "./pageComponents/ResourceMonitorPodList";

function EdgeResourceMonitor() {
  const dispatch = useDispatch();
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");

  let curr_App_reloadValue = useSelector(
    (state) => state.setInstallAppsReloader
  );
  const [apiLoaded, setApiLoaded] = useState(false);

  let [installedAppLoaded, setInstallAppLoaded] = useState(false);
  const [displayApplications, setDisplayApplications] = useState(true);
  const handleDisplayChange = (val) => {
    setDisplayApplications(val === "installedApplications");
  };

  useEffect(() => {
    dataCollection();
  }, [curr_App_reloadValue]);
  // useEffect(() => {
  //   if (displayApplications) {
  //     dataCollection();
  //   }
  // }, [displayApplications]);

  const dataCollection = () => {
    setApiLoaded(false);
    setInstallAppLoaded(false);
    //List of Installed Applications
    let toGetInstalledApps = apiEndPoints.FETCH_INSTALLED_APPS.replace(
      "*edgeId*",
      edgeId
    );

    let Fetch_Installedapps = {
      method: "get",
      maxBodyLength: Infinity,
      url: toGetInstalledApps,
      headers: {
        // Authorization: `Bearer ${access_token}`,
      },
    };
    axios
      .request(Fetch_Installedapps)
      .then((response) => {
        dispatch(setInstalledApps({ payload: response.data }));
        if (response.status == 200) {
          setInstallAppLoaded(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (installedAppLoaded) {
      setApiLoaded(true);
    }
  }, [installedAppLoaded]);
  return (
    <section className="row-flex">
      <div className="sidebarContainer">
        <Sidebar />
      </div>
      <div className="MainContainer">
        <Row style={{ gridGap: "10px", paddingRight: "20px" }}>
          <Col flex="0 1 100%" sm={24} md={24} lg={24} xl={6}>
            <Row>
              <Col
                className="gridContainer"
                style={{ borderRadius: "10px", height: "92vh", width: "92vw" }}
              >
                <Select
                  defaultValue="installedApplications"
                  style={{
                    width: "100%",
                    padding: "10px",
                    height: "50px",
                  }}
                  // onChange={handleDisplayChange}
                  onChange={(val) => handleDisplayChange(val)}
                  options={[
                    {
                      value: "installedApplications",
                      label: "Application Instances",
                    },
                    {
                      value: "edgesCore",
                      label: "Edge Core Runtime",
                    },
                  ]}
                />

                <div>
                  {displayApplications ? (
                    <ResourceMonitorApplications apiLoaded={apiLoaded} />
                  ) : (
                    <ResourceMonitorPodList />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            flex="1 1 525px"
            className="gridContainer"
            style={{
              columnGap: "10px",
              display: "grid",
              rowGap: "10px",
            }}
          >
            <ResourceMonitorGraphs />
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default EdgeResourceMonitor;
